import { AxiosInstance } from "axios";
import { createServiceFormURL, deleteServiceFormURL, getAllServiceFormRecordsURL, getAllServiceFormsURL, updateServiceFormURL, updateStatusServiceFormURL } from "../global";
import { ServiceForm } from "../models/service-form.model";
import { ServiceStatus } from "../enums/service-status.enum";
import { UpdateStatusServiceForm } from "../models/update-status-service-form.model";
import { NewServiceForm } from "../models/new-service-form.model";
import { UpdateServiceForm } from "../models/update-service-form.model";
import { ServiceFormRecordFilter } from "../models/service-form-record-filter.model";
import { ServiceFormRecordPage } from "../models/service-form-record-page.model";

export interface ServiceFormServiceType {
	getAllServiceFormRecords: (payload: ServiceFormRecordFilter) => Promise<ServiceFormRecordPage>
	getAllServiceForms: () => Promise<ServiceFormMap>
	createServiceForm: (payload: NewServiceForm) => Promise<void>
	deleteServiceForm: (id: number) => Promise<void>
	updateStatusServiceForm: (payload: UpdateStatusServiceForm) => Promise<void>
	updateServiceForm:  (payload: UpdateServiceForm) => Promise<void>
}
export type ServiceFormMap = Record<keyof typeof ServiceStatus, ServiceForm[]>;

export const ServiceFormService = ({ api }: { api: AxiosInstance }): ServiceFormServiceType => {

	const getAllServiceFormRecords = (payload: ServiceFormRecordFilter) => {
		return api.post<ServiceFormRecordFilter, ServiceFormRecordPage>(getAllServiceFormRecordsURL, payload);
	}

	const getAllServiceForms = () => {
		return api.get<void, ServiceFormMap>(getAllServiceFormsURL);
	}

	const createServiceForm = (payload: NewServiceForm) => {
		return api.post<NewServiceForm, void>(createServiceFormURL, payload);
	}

	const updateStatusServiceForm = (payload: UpdateStatusServiceForm) => {
		return api.put<UpdateStatusServiceForm, void>(updateStatusServiceFormURL, payload);
	}

	const updateServiceForm = (payload: UpdateServiceForm) => {
		return api.put<void, void>(`${updateServiceFormURL}`, payload);
	}

	const deleteServiceForm = (id: number) => {
		return api.delete<void, void>(`${deleteServiceFormURL}/${id}`);
	}

	return {
		getAllServiceFormRecords,
		getAllServiceForms,
		createServiceForm,
		updateStatusServiceForm,
		updateServiceForm,
		deleteServiceForm
	};

}