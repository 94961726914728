import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "../shared/custom-data-grid";
import { useEffect, useState } from "react";
import { Shelf } from "../../models/shelf";
import { useService } from "../../provider/service-provider";
import { IconButton, Stack, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomAddButton from "../shared/custom-add-button";
import { useDialog } from "../../provider/dialog-provider";
import AddNewShelf from "./add-new-shelf";
import { NewShelf } from "../../models/new-shelf";
import CustomConfirmation from "../shared/custom-confimation";

const ShelfTable = () => {

	const { shelfService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();
	
	const [rows, setRows] = useState<Shelf[]>([]);

	const getAllShelfs = () => {
		shelfService.getAllShelfs().then(setRows)
	}

	useEffect(getAllShelfs, []);

	const createShelf = (newShelf: NewShelf) => {
		shelfService.createShelf(newShelf).then(() => {
			handleCloseDialog();
			getAllShelfs();
		});
	}

	const openConfirmationDialog = (id: number) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteShelf(id)} subtitle="Bu rafa bağlı stok kayıtları da silinecektir." />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const deleteShelf = (id: number) => {
		shelfService.deleteShelf(id).then(() => {
			handleCloseDialog();
			getAllShelfs();
		});
	}

	const openNewShelfDialog = () => {
		handleOpenDialog(<AddNewShelf handleSave={createShelf} />, {
			headerTitle: "Yeni Raf Ekle",
			haveCloseIcon: true
		});
	}

	const columns: GridColDef[] = [
		{ field: 'id', headerName: "#", width: 70 },
		{ field: 'name', headerName: "Raf", width: 200 },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					<IconButton
						color="error"
						onClick={() => openConfirmationDialog(params.row.id)}
					>
						<DeleteOutlineOutlinedIcon />
					</IconButton>
				</Stack>
			),
		}
	];

	return (
		<>
			<Stack flexDirection="row" justifyContent="space-between">
				<Typography fontSize="20px" fontWeight={600}>Raflar</Typography>
				<CustomAddButton title="Ekle" onClick={openNewShelfDialog} />
			</Stack>
			<CustomDataGrid
				columns={columns}
				rows={rows}
				disableColumnMenu
				disableColumnResize
				disableRowSelectionOnClick
				initialState={{
					pagination: {
						paginationModel: { pageSize: 3 },
					},
				}}
			/>
		</>

	);
}

export default ShelfTable;