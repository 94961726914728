import axios, { AxiosError, AxiosInstance } from "axios";
import { useSnackbar } from "notistack";
import { FC, ReactNode, createContext, useContext, useMemo, useState } from "react";
import { apiBaseURL } from "../global";
import { useNavigate } from "react-router";

interface ApiContextType {
	loading: boolean
	api: AxiosInstance
}

const ApiContext = createContext<ApiContextType | null>(null);

export const ApiProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const axiosInstance = useMemo(() => {
		const instance = axios.create({
			baseURL: apiBaseURL,
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
				'Accept': '*/*',
			},
		})

		instance.interceptors.request.use((config) => {
			setLoading(true);
			return config;
		})

		instance.interceptors.response.use(
			(response) => {
				setLoading(false);
				return response?.data || response;
			},
			(error: AxiosError) => {
				setLoading(false);

				if (error?.response) {
					const statusCode = error.response.status;
					if (statusCode === 404) {
						navigate('/error');
					} else if (statusCode === 403 ||statusCode === 401) {
						localStorage.removeItem("fullName")
						localStorage.removeItem("role")
						navigate('/login');
					} else {
						enqueueSnackbar(JSON.stringify(error.response.data), { variant: "error" });
					}

					throw error
				} else {
					navigate('/error');
				}
			}
		)

		return instance;
	}, []);

	const contextValues: ApiContextType = {
		loading,
		api: axiosInstance
	};

	return (
		<ApiContext.Provider value={contextValues}>
			{children}
		</ApiContext.Provider>
	);
};

export const useApi = (): ApiContextType => {
	const context = useContext(ApiContext);
	if (!context) {
		throw new Error('useApi must be used within a ApiProvider');
	}
	return context;
};