import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "../shared/custom-data-grid";
import { useEffect, useState } from "react";
import { Model } from "../../models/model";
import { useService } from "../../provider/service-provider";
import { IconButton, Stack, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomAddButton from "../shared/custom-add-button";
import AddNewModel from "./add-new-model";
import { NewModel } from "../../models/new-model";
import { useDialog } from "../../provider/dialog-provider";
import CustomConfirmation from "../shared/custom-confimation";
import { Role } from "../../enums/role";

const ModelTable = () => {

	const { modelService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [rows, setRows] = useState<Model[]>([]);

	const getAllModels = () => {
		modelService.getAllModels().then(setRows)
	}

	useEffect(getAllModels, []);

	const openConfirmationDialog = (id: number) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteModel(id)} subtitle="Bu modele bağlı stok kayıtları da silinecektir." />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const deleteModel = (id: number) => {
		modelService.deleteModel(id).then(() => {
			handleCloseDialog();
			getAllModels();
		});
	}

	const createModel = (newModel: NewModel) => {
		modelService.createModel(newModel).then(() => {
			handleCloseDialog();
			getAllModels();
		});
	}

	const openNewModelDialog = () => {
		handleOpenDialog(<AddNewModel handleSave={createModel} />, {
			headerTitle: "Yeni Model Ekle",
			haveCloseIcon: true
		});
	}

	const columns: GridColDef[] = [
		{ field: 'id', headerName: "#", width: 70 },
		{ field: 'brandName', headerName: "Marka", width: 150 },
		{ field: 'name', headerName: "Model", width: 200 },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					{localStorage.getItem("role") == Role.ADMIN && (
						<IconButton
							color="error"
							onClick={() => openConfirmationDialog(params.row.id)}
						>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					)}
				</Stack>
			),
		}
	];

	return (
		<>
			<Stack flexDirection="row" justifyContent="space-between">
				<Typography fontSize="20px" fontWeight={600}>Modeller</Typography>
				<CustomAddButton title="Ekle" onClick={openNewModelDialog} />
			</Stack>
			<CustomDataGrid
				columns={columns}
				rows={rows}
				disableColumnMenu
				disableColumnResize
				disableRowSelectionOnClick
				initialState={{
					pagination: {
						paginationModel: { pageSize: 3 },
					},
				}}
			/>
		</>
	);
}

export default ModelTable;