import { Navigate, Route, Routes } from "react-router-dom";
import { pages } from "../global";
import uuid from "react-uuid";
import Error from "../pages/error";
import Layout from "../layout/layout";
import Login from "../pages/login";
import ProtectedRoute from "./protected-route";
import { Role } from "../enums/role";

const Routing = () => {

	const role = localStorage.getItem('role');

	const getRedirectPath = () => {
		if (!role) {
			return '/login';
		}
		if (role === Role.USER) {
			return '/musteri-yonetimi';
		}
		if (role === Role.ADMIN) {
			return '/kullanici-yonetimi';
		}
		return '/error';
	};

	return (
		<Routes>
			<Route path="/error" element={<Error />} />
			<Route path="/login" element={<Login />} />
			<Route element={<Layout />}>
				{
					pages.map((page) => (
						<Route
							key={uuid()}
							path={page.path}
							element={
								<ProtectedRoute roles={page.roles}>
									{page.element}
								</ProtectedRoute>
							}
						/>
					))
				}
			</Route>
			<Route path="/" element={<Navigate to={getRedirectPath()} />} />
		</Routes>
	);
};

export default Routing;