import { FC, useEffect, useState } from "react";
import { Autocomplete, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useDialog } from "../../provider/dialog-provider";
import { ServiceForm } from "../../models/service-form.model";
import ServiceFormPrintDetail from "./service-form-print-detail";
import { UpdateServiceForm } from "../../models/update-service-form.model";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ServiceFormPart } from "../../models/service-form-part";
import { useService } from "../../provider/service-provider";
import { Stock } from "../../models/stock.model";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import uuid from "react-uuid";

interface UpdateServiceFormProps {
	serviceForm: ServiceForm
	handleSave: (updatedServiceForm: UpdateServiceForm) => void
}
const UpdateStatusServiceForm: FC<UpdateServiceFormProps> = ({ serviceForm, handleSave }) => {

	const { stockService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [laborCost, setLaborCost] = useState<number>(serviceForm.laborCost);
	const [partsCost, setPartsCost] = useState<number>(serviceForm.partsCost);
	const [customerNotes, setCustomerNotes] = useState<string>(serviceForm.customerNotes);
	const [notes, setNotes] = useState<string>(serviceForm.notes);

	const handleClickSave = () => {
		handleSave({
			id: serviceForm.id,
			laborCost,
			notes,
			customerNotes,
			parts
		});
	};

	const handleOpenPrintDialog = () => {
		handleOpenDialog(<ServiceFormPrintDetail serviceForm={serviceForm} />)
	}

	const [selectedStock, setSelectedStock] = useState<Stock>();
	const [stocks, setStocks] = useState<Stock[]>([]);

	useEffect(() => {
		stockService.getAllStocks().then(setStocks)
	}, []);

	const [parts, setParts] = useState<ServiceFormPart[]>(serviceForm.parts);

	const getParts = parts.filter(part => part.status !== "DELETED");

	const addNewPart = () => {
		selectedStock && setParts((prevParts) => {

			const stockIndex = prevParts.findIndex(part => part.stockId == selectedStock.id);

			if(stockIndex != -1) {

				const array = [...prevParts];

				if(array[stockIndex].status == "DELETED") {
					array[stockIndex].status = "UPDATED";
				}

				return array;

			} else {
				return [
					...prevParts,
					{ stockId: selectedStock.id, description: selectedStock.productName, price: null, quantity: null, status: "ADDED" }
				];
			}

		});
	};

	const updatePart = (index: number, fieldName: string, value: string | number | undefined) => {
		setParts((prevParts) => {
			const array = [...prevParts];
			return array.map((part, i) =>
				i === index ? {
					...part,
					status: part.status !== "ADDED" ? "UPDATED" : "ADDED",
					[fieldName]: value
				} : part
			)
		});
	}

	const deletePart = (index: number) => {
		setParts(prevParts => {

			const array = [...prevParts];

			if (array[index].status == "ADDED") {
				array.splice(index, 1);
			} else {
				array[index].status = "DELETED";
			}

			return array;

		});
	}

	useEffect(() => {
		setPartsCost(
			parts.reduce((acc, part) => {
				const quantity = part.quantity || 0; // null ise 0 yap
				const price = part.price || 0;       // null ise 0 yap
				return acc + (quantity * price);
			}, 0)
		)
	}, [parts])

	const isDisabled = (parts.length != 0) && (parts.findIndex(part => !part.price || !part.quantity || !part.stockId) != -1);

	return (
		<Stack spacing={2}>
			<Stack flexDirection="row" gap="10px">
				<Stack flex={1}>
					<Typography variant="subtitle2">Müşteri</Typography>
					<TextField value={serviceForm.customer.fullName} disabled />
				</Stack>

				<Stack flex={1}>
					<Typography variant="subtitle2">Müşteri Telefon</Typography>
					<TextField value={serviceForm.customer.phoneNumber} disabled />
				</Stack>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Plaka</Typography>
				<TextField value={serviceForm.vehicle.licensePlate} disabled />
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Tarih</Typography>
				<TextField value={dayjs(serviceForm.serviceDate).format("DD.MM.YYYY HH:mm")} disabled />
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Kilometre</Typography>
				<TextField value={serviceForm.mileage} disabled />
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Müşteri Notları</Typography>
				<TextField
					fullWidth
					variant="outlined"
					placeholder="Müşteri Notları"
					value={customerNotes || ''}
					multiline
					rows={5}
					onChange={(e) => setCustomerNotes(e.target.value)}
				/>
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Not</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="partsCost"
					placeholder="Not"
					multiline
					rows={(notes?.split('\n').length || 1)}
					value={notes || ''}
					onChange={(e) => setNotes(e.target.value)}
				/>
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Parça Ekle</Typography>

				<Stack direction="row" gap={1} alignItems="center">

					<Autocomplete
						style={{ width: 300 }}
						options={stocks}
						onChange={(event, stock) => stock && setSelectedStock(stock)}
						getOptionLabel={(option) => option.productName} // Gösterilecek label
						renderInput={(params) => (
							<TextField {...params} variant="outlined" />
						)}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
									<p>{option.productName}</p>
									<p style={{ color: "gray", fontSize: 10 }}>{option.brand} - {option.model}</p>
								</li>
							);
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>

					<IconButton disabled={!selectedStock} color="success" onClick={() => addNewPart()} >
						<AddCircleOutlineIcon />
					</IconButton>

				</Stack>

			</Stack>

			{

				getParts.length ? (
					<Stack direction="row" width="100%" gap={2} alignItems="center" justifyContent="space-between" paddingTop={1}>
						<Stack width={300} gap={1}>
							<Typography variant="subtitle2">Parça Açıklaması</Typography>
						</Stack>
						<Stack width={100} gap={1}>
							<Typography variant="subtitle2">Miktar</Typography>
						</Stack>
						<Stack width={150} gap={1}>
							<Typography variant="subtitle2">Fiyat</Typography>
						</Stack>
					</Stack>
				) : null

			}

			{

				getParts.map((part, index) => (

					<Stack key={index} direction="row" gap={2} alignItems="center" justifyContent="space-between">
						<Stack width={300} gap={1}>
							<TextField
								fullWidth
								variant="outlined"
								placeholder="Parça Açıklaması"
								value={part.description}
								disabled />
						</Stack>
						<Stack width={100} gap={1}>
							<TextField
								fullWidth
								variant="outlined"
								placeholder="Miktar"
								value={part.quantity}
								type="number"
								onChange={(e) => updatePart(index, "quantity", Number(e.target.value))} />
						</Stack>
						<Stack width={100} gap={1}>
							<TextField
								fullWidth
								variant="outlined"
								placeholder="Fiyat"
								value={part.price}
								type="number"
								onChange={(e) => updatePart(index, "price", Number(e.target.value))} />
						</Stack>
						<IconButton color="error" onClick={() => deletePart(index)} >
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					</Stack>

				))

			}

			<Stack flexDirection="row" gap="10px">
				<Stack flex={1}>
					<Typography variant="subtitle2">İşçilik Fiyatı</Typography>
					<TextField
						fullWidth
						variant="outlined"
						name="laborCost"
						placeholder="İşçilik Fiyatı"
						value={laborCost}
						type="number"
						onChange={(e) => setLaborCost(Number(e.target.value))}
					/>
				</Stack>

				<Stack flex={1}>
					<Typography variant="subtitle2">Parça Fiyatı</Typography>
					<TextField
						fullWidth
						variant="outlined"
						name="partsCost"
						placeholder="Parça Fiyatı"
						value={partsCost}
						type="number"
						disabled
					/>
				</Stack>
			</Stack>

			<Stack >
				<Typography variant="subtitle2">Toplam Fiyat</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="partsCost"
					placeholder="Parça Fiyatı"
					disabled
					value={(Number(laborCost) + Number(partsCost))}
					type="number"
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleOpenPrintDialog}>
					Yazdır
				</Button>
			</Stack>

		</Stack>
	);
}

export default UpdateStatusServiceForm;