import { Button, Stack, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useDialog } from "../../provider/dialog-provider";
import { useService } from "../../provider/service-provider";
import Vehicles from "./vehicles";
interface AddNewVehicleProps {
	customerId: number
}
const AddNewVehicle: FC<AddNewVehicleProps> = ({ customerId }) => {

	const { vehicleService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [licensePlate, setLicensePlate] = useState("");
	const [brand, setBrand] = useState("");
	const [model, setModel] = useState("");
	const [year, setYear] = useState("");
	const [color, setColor] = useState("");
	const [chassisNumber, setChassisNumber] = useState('');

	const handleClickSave = () => {
		const newVehicle = { customerId, licensePlate, brand, model, year : Number(year), color, chassisNumber };
		vehicleService.createVehicle(newVehicle).then(() => {
			handleOpenDialog(<Vehicles customerId={customerId} />, {
				headerTitle: "Araçlar",
				haveCloseIcon: true
			});
		})
	};

	const handleClickCancel = () => {
		handleOpenDialog(<Vehicles customerId={customerId} />, {
			headerTitle: "Araçlar",
			haveCloseIcon: true
		});
	}
	
	const isDisabled = !licensePlate || !brand || !model || !year || !color;

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Plaka</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Plaka"
					value={licensePlate}
					onChange={(e) => setLicensePlate(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Şase Numarası</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="chassisNumber"
					placeholder="Şase Numarası"
					value={chassisNumber}
					onChange={(e) => setChassisNumber(e.target.value.toUpperCase())}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Marka</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="fullName"
					placeholder="Marka"
					value={brand}
					onChange={(e) => setBrand(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Model</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="phoneNumber"
					placeholder="Model"
					value={model}
					onChange={(e) => setModel(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Yıl</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="email"
					placeholder="Yıl"
					value={year}
					onChange={(e) => setYear(e.target.value)}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Renk</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="address"
					placeholder="Renk"
					value={color}
					onChange={(e) => setColor(e.target.value)}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="outlined" onClick={handleClickCancel}>
					İptal
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack>
	);
}

export default AddNewVehicle;