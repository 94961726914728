import { FC, useEffect, useState } from "react";
import { Stock } from "../../models/stock.model";
import { useDialog } from "../../provider/dialog-provider";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { UpdatedStock } from "../../models/updated-stock.model";

interface UpdateStockProps {
	handleSave: (updatedStock: UpdatedStock) => void,
	stock: Stock
}
const UpdateStock: FC<UpdateStockProps> = ({ handleSave, stock }) => {

	const { handleCloseDialog } = useDialog();

	const [updatedStock, setUpdatedStock] = useState<UpdatedStock>({ ...stock });

	const setFieldUpdatedStock = (fieldName: keyof UpdatedStock, value: string | number) => {
		setUpdatedStock(prev => ({ ...prev, [fieldName]: value }));
	}

	const handleClickSave = () => {
		handleSave(updatedStock);
	};

	const isDisabled =
		!updatedStock.productName ||
		!(updatedStock.quantityInStock > -1) ||
		!updatedStock.purchasePrice;

	return (
		<Stack spacing={2}>
			<Stack>
				<Typography variant="subtitle2">Stok Kodu</Typography>
				<TextField
					disabled
					fullWidth
					variant="outlined"
					name="code"
					value={stock.productCode}
				/>
			</Stack>

			<Stack>
				< Typography variant="subtitle2">Stok Adı</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="phoneNumber"
					placeholder="Stok Adı"
					value={updatedStock.productName}
					onChange={(e) => setFieldUpdatedStock("productName", e.target.value)}
				/>
			</Stack>

			<Stack flexDirection="row" gap="10px" >
				<Stack flex={1}>
					<Typography variant="subtitle2">Marka</Typography>
					<TextField
						disabled
						fullWidth
						variant="outlined"
						name="brandId"
						value={stock.brand}
					/>
				</Stack>

				<Stack flex={1}>
					<Typography variant="subtitle2">Model</Typography>
					<TextField
						disabled
						fullWidth
						variant="outlined"
						name="modelId"
						value={stock.model}
					/>
				</Stack>
			</Stack >

			<Stack flexDirection="row" gap="10px">
				<Stack flex={1}>
					<Typography variant="subtitle2">Kategori</Typography>
					<TextField
						disabled
						fullWidth
						variant="outlined"
						name="categoryId"
						value={stock.category}
					/>
				</Stack>

				<Stack flex={1}>
					<Typography variant="subtitle2">Raf</Typography>
					<TextField
						disabled
						fullWidth
						variant="outlined"
						name="shelf"
						value={stock.shelf}
					/>
				</Stack>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Miktar</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="mileage"
					placeholder="Miktar"
					value={updatedStock.quantityInStock}
					type="number"
					onChange={(e) => setFieldUpdatedStock("quantityInStock", Number(e.target.value))}
				/>
			</Stack>

			<Stack>
				<Typography variant="subtitle2">Birim Fiyat</Typography>
				<TextField
					fullWidth
					variant="outlined"
					name="mileage"
					placeholder="Birim Fiyat"
					value={updatedStock.purchasePrice}
					type="number"
					onChange={(e) => setFieldUpdatedStock("purchasePrice", Number(e.target.value))}
				/>
			</Stack>

			<Stack direction="row" spacing={2} justifyContent="flex-end">
				<Button sx={{ flex: 1 }} variant="outlined" onClick={handleCloseDialog}>
					İptal
				</Button>
				<Button disabled={isDisabled} sx={{ flex: 1 }} variant="contained" onClick={handleClickSave}>
					Kaydet
				</Button>
			</Stack>
		</Stack >
	)
}

export default UpdateStock;