import { Box, Dialog, IconButton, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import CloseIcon from '@mui/icons-material/Close';

interface CustomDialogProps {
	open: boolean
	data?: CustomDialogData
	onClose: () => void
	DialogComponent: ReactElement | null
}
export interface CustomDialogData {
	headerTitle?: string
	haveCloseIcon?: boolean
}
const CustomDialog: React.FC<CustomDialogProps> = ({ open, data, DialogComponent, onClose }) => {

	return (
		<Dialog open={open} sx={{"& .MuiDialog-paper": { maxWidth: "none" }}}>
			<Stack p={3}>
				<Stack flexDirection="row" justifyContent="space-between" alignItems="center">
					{data?.headerTitle &&
						<Typography variant="h6">{data.headerTitle}</Typography>
					}
					{data?.haveCloseIcon &&
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					}
				</Stack>
				<Box sx={{ pt: 2, minWidth: "500px" }}>
					<Box>
						{DialogComponent}
					</Box>
				</Box>
			</Stack>
		</Dialog>
	);

};

export default CustomDialog;