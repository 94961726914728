import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "../shared/custom-data-grid";
import { useEffect, useState } from "react";
import { Category } from "../../models/category";
import { useService } from "../../provider/service-provider";
import { IconButton, Stack, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomAddButton from "../shared/custom-add-button";
import { useDialog } from "../../provider/dialog-provider";
import AddNewCategory from "./add-new-category";
import { NewCategory } from "../../models/new-category";
import CustomConfirmation from "../shared/custom-confimation";
import { Role } from "../../enums/role";

const CategoryTable = () => {

	const { categoryService } = useService();
	const { handleOpenDialog, handleCloseDialog } = useDialog();

	const [rows, setRows] = useState<Category[]>([]);

	const getAllCategories = () => {
		categoryService.getAllCategories().then(setRows)
	}

	useEffect(getAllCategories, []);

	const createCategory = (newCategory: NewCategory) => {
		categoryService.createCategory(newCategory).then(() => {
			handleCloseDialog();
			getAllCategories();
		});
	}

	const openConfirmationDialog = (id: number) => {
		handleOpenDialog(<CustomConfirmation handleClickConfirm={() => deleteCategory(id)} subtitle="Bu kategoriye bağlı stok kayıtları da silinecektir." />, {
			headerTitle: "Emin Misiniz?",
			haveCloseIcon: true
		});
	}

	const deleteCategory = (id: number) => {
		categoryService.deleteCategory(id).then(() => {
			handleCloseDialog();
			getAllCategories();
		});
	}

	const openNewCategoryDialog = () => {
		handleOpenDialog(<AddNewCategory handleSave={createCategory} />, {
			headerTitle: "Yeni Kategori Ekle",
			haveCloseIcon: true
		});
	}

	const columns: GridColDef[] = [
		{ field: 'id', headerName: "#", width: 70 },
		{ field: 'name', headerName: "Kategori", width: 200 },
		{
			field: "actions", headerName: "", flex: 1,
			renderCell: (params) => (
				<Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" height="100%">
					{localStorage.getItem("role") == Role.ADMIN && (
						<IconButton
							color="error"
							onClick={() => openConfirmationDialog(params.row.id)}
						>
							<DeleteOutlineOutlinedIcon />
						</IconButton>
					)}
				</Stack>
			),
		}
	];

	return (
		<>
			<Stack flexDirection="row" justifyContent="space-between">
				<Typography fontSize="20px" fontWeight={600}>Kategoriler</Typography>
				<CustomAddButton title="Ekle" onClick={openNewCategoryDialog} />
			</Stack>
			<CustomDataGrid
				columns={columns}
				rows={rows}
				disableColumnMenu
				disableColumnResize
				disableRowSelectionOnClick
				initialState={{
					pagination: {
						paginationModel: { pageSize: 3 },
					},
				}}
			/>
		</>

	);
}

export default CategoryTable;